import $ from 'jquery';
import axios from 'axios';

function buildMap(mapElement, google, position) {
  if (position) {
    const { lat, lng, zoom } = position;

    return new google.maps.Map(mapElement, {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      center: { lat, lng },
      zoom,
    });
  }

  return new google.maps.Map(mapElement, { mapTypeId: google.maps.MapTypeId.ROADMAP, clickableIcons: false });
}

function buildPolyline(positions, google) {
  return new google.maps.Polyline({
    path: positions,
    strokeColor: '#FF0000',
    strokeOpacity: 0.3,
    strokeWeight: 5,
  });
}

function SpotMap(mapId, Marker, google, position) {
  const element = document.getElementById(mapId);
  const map = buildMap(element, google, position);
  const infoWindow = new google.maps.InfoWindow();

  const state = {
    markers: [],
    searchMarkers: {},
  };

  const removeMarkers = () => state.markers.forEach(marker => marker.setMap(null));

  const createMarkers = (spots, icon) => spots.map(spot =>
    new Marker(spot, map, infoWindow, google, icon));

  const removePolyLine = () => { if (state.polyLine) { state.polyLine.setMap(null); } };

  const setPolyline = () => {
    state.polyLine = buildPolyline(state.markers.map(marker => marker.getPosition()), google);
    state.polyLine.setMap(map);
  };

  const boundMarkers = () => {
    if (state.markers.length > 1) { return state.markers; }

    const markers = Object.keys(state.searchMarkers)
      .reduce((searchMarkers, key) =>
        searchMarkers.concat(state.searchMarkers[key].markers || []), []);

    return markers.length !== 0 ? markers : state.markers;
  };

  const bounds = () => boundMarkers().reduce(
    (bound, marker) => bound.extend(marker.getPosition()),
    new google.maps.LatLngBounds(),
  );

  const focus = () => {
    if (boundMarkers().length !== 0) {
      map.fitBounds(bounds());
    }
    if (boundMarkers().length === 1) {
      map.setZoom(17);
    }
  };

  const setSearchMarkers = ({ key, spots, icon }) => {
    if (state.searchMarkers[key]) {
      const markers = createMarkers(spots, icon);
      const exsitingMakers = state.searchMarkers[key].markers || [];
      state.searchMarkers[key].markers = exsitingMakers.concat(markers);
    }
  };

  const getSearchParams = (key) => {
    const {
      q, style, view, icon,
    } = (state.searchMarkers[key] || {});

    if (!q) { return null; }

    // Add close plan param to search query
    if (state.planId && !q.favorite) { q.close_to_plan_id = state.planId; }
    return {
      q, style, view, icon,
    };
  };

  const fetchSearchMarkers = ({ key, page }) => {
    const {
      q, style, view, icon,
    } = getSearchParams(key);

    if (q) {
      const params = $.param({
        q,
        page,
        style,
        view,
      });
      axios.get(`${element.dataset.url}?${params}`).then(({ status, data }) => {
        if (status === 200) {
          if (data.length !== 0) {
            setSearchMarkers({ key, spots: data, icon });
            fetchSearchMarkers({ key, page: page + 1 });
          }

          if (data.length === 0 || page === 1) {
            focus();
          }
        }
      }).catch((error) => { console.log(error); });
    }
  };

  const removeSearchMarkers = (key) => {
    const query = state.searchMarkers[key];

    if (query && query.markers) { query.markers.forEach(marker => marker.setMap(null)); }
  };

  const setSearchParams = ({
    key,
    q,
    style,
    view,
    icon,
  }) => {
    state.searchMarkers[key] = {
      q, style, view, icon,
    };
  };

  const toggleQuery = ({
    key,
    q,
    style,
    view,
    icon,
  }) => {
    if (state.searchMarkers[key]) {
      removeSearchMarkers(key);
      delete state.searchMarkers[key];
      focus();
    } else {
      setSearchParams({
        key,
        q,
        style,
        view,
        icon,
      });
      fetchSearchMarkers({ key, page: 1 });
    }
  };

  const updateSearchMarkers = () => {
    Object.keys(state.searchMarkers).forEach((key) => {
      removeSearchMarkers(key);
      fetchSearchMarkers({ key, page: 1 });
    });
  };

  return {
    setPlan: ({ spots, planId }) => {
      state.planId = planId;
      removeMarkers();
      state.markers = createMarkers(spots);
      removePolyLine();
      setPolyline();
      focus();
      infoWindow.close();
    },
    closeInfoWindow: () => infoWindow.close(),
    toggleFirstMarker: () => google.maps.event.trigger(state.markers[0], 'click'),
    toggleQuery: data => toggleQuery(data),
    setQuery: ({
      key,
      q,
      style,
      view,
    }) => {
      removeSearchMarkers(key);
      delete state.searchMarkers[key];
      setSearchParams({
        key,
        q,
        style,
        view,
      });
      fetchSearchMarkers({ key, page: 1 });
    },
  };
}

function ExportMarker(spot, map, infoWindow, google, icon) {
  const {
    position,
    view,
    title,
    label,
  } = spot;

  const marker = new google.maps.Marker({
    position, map, title, label, icon,
  });

  marker.addListener('click', () => {
    infoWindow.close();
    infoWindow.setContent(view || title);
    infoWindow.open(map, marker);
  });

  return marker;
}

function buildMarkerElement(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}

function HTMLMarker(spot) {
  this.spot = spot;

  this.getPosition = () => new google.maps.LatLng(this.spot.position);

  this.remove = () => {
    if (!this.div) { return; }
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  };

  this.draw = () => {
    if (!this.div) {
      this.div = buildMarkerElement(this.spot.marker_html);

      google.maps.event.addDomListener(this.div, 'click', () => {
        google.maps.event.trigger(this, 'click');
      });

      const panes = this.getPanes();
      panes.overlayImage.appendChild(this.div);
    }

    const { x, y } = this.getProjection().fromLatLngToDivPixel(this.getPosition());

    this.div.style.left = `${x}px`;
    this.div.style.top = `${y}px`;
  };

  return Object.setPrototypeOf(this, new google.maps.OverlayView());
}

function PlanMarker(spot, map, infoWindow, google) {
  const marker = new HTMLMarker(spot, google);
  const { view, title } = spot;

  marker.addListener('click', () => {
    infoWindow.close();
    infoWindow.setContent(view || title);
    infoWindow.open(map, marker);
  });

  marker.setMap(map);
  return marker;
}

export default function loadMap(google) {
  if ($('#export-map').length > 0) {
    window.spotMap = new SpotMap('export-map', ExportMarker, google);
    window.spotMap.setPlan($('#export-map').data());
    window.spotMap.toggleFirstMarker();
  }
  if ($('#plan-map').length > 0) {
    window.spotMap = new SpotMap('plan-map', PlanMarker, google);
    window.spotMap.setPlan($('#plan-map').data());
    window.spotMap.toggleQuery({
      key: 'favorite',
      view: 'plan',
      style: 0,
      q: { favorite: true },
    });
  }

  if ($('#spots-map').length > 0) {
    window.spotMap = new SpotMap('spots-map', ExportMarker, google, $('#area-map').data());
  }

  if ($('#area-map').length > 0) {
    window.spotMap = new SpotMap('area-map', ExportMarker, google, $('#area-map').data());
  }

  $('.map-other-spots-select-ui__cat-item').on('click', (event) => {
    const element = $(event.target);
    element.toggleClass('map-other-spots-select-ui__cat-item--state_active');
    window.spotMap.toggleQuery(Object.assign(element.data(), { view: 'plan' }));
  });

  $('.area-map-category-menu').on('click', (event) => {
    event.preventDefault();
    const element = $(event.target);
    element.toggleClass('btn--type_tool--state_active');
    window.spotMap.toggleQuery(element.data());
  });
}
