import { Controller } from 'stimulus';
const USER_IDENTIFIED = 'user-identified';

export default class extends Controller {
  connect() {
    document.body.addEventListener(USER_IDENTIFIED, this.reload, true);
  }

  disconnect() {
    document.body.removeEventListener(USER_IDENTIFIED, this.reload, true);
  }

  reload = () => {
    document.location.href = this.path;
  }

  get path() {
    return this.data.get('path');
  }
}
