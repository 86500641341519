import Chart from 'chart.js';

function vectorLenght(x, y) {
  return Math.sqrt((x ** 2) + (y ** 2));
}

function vectorDregree(x, y) {
  const degree = Math.acos(-y / vectorLenght(x, y)) * (180 / Math.PI);
  const orientation = x < 0 ? -1 : 1;
  return 180 + (degree * orientation);
}

function categoryByXY({ categories, x, y }) {
  const degree = vectorDregree(x, y);
  const degreeRange = 360 / categories.length;
  const halfDegreeRange = degreeRange / 2;

  return categories.find((category, index) => {
    const categoryDegree = index * degreeRange;
    const minDegree = categoryDegree - halfDegreeRange;
    const maxDegree = categoryDegree + halfDegreeRange;

    // The index category is between 360 - "minDegree" and 0 + "maxDegee"
    if (index === 0 && (degree > (360 - halfDegreeRange) || degree < maxDegree)) return true;
    if (degree >= minDegree && degree < maxDegree) return true;
    return false;
  });
}

function radarChartXY(event, chart) {
  const { x, y } = Chart.helpers.getRelativePosition(event, chart);
  const { xCenter, yCenter } = chart.scale;

  return { x: xCenter - x, y: yCenter - y };
}

function radarChartValue({ x, y, scale }) {
  const { min, max, drawingArea } = scale;
  const pixelDistance = vectorLenght(x, y);
  const pixelStep = drawingArea / (max - min);
  const value = Math.round(pixelDistance / pixelStep) + min;

  return value > max ? max : value;
}

function defaultOption() {
  return {
    layout: {
      padding: {
        top: 20,
      },
    },
    title: {
      display: false,
      text: 'Custom Chart Title',
    },
    legend: { display: false },
    elements: { line: { tension: 0 } },
    scale: {
      type: 'radialLinear',
      ticks: {
        beginAtZero: true,
        max: 100,
        userCallback: item => `${item}%`,
      },
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      displayColors: false,
      callbacks: {
        label: tooltipItems => `${tooltipItems.yLabel}%`,
      },
    },
  };
}

function keyByName(state, name) { return state.find(data => data.name === name).key; }

export default function radarChart(elementId, state) {
  const element = document.getElementById(elementId);

  const myRadarChart = new Chart(element, {
    type: 'radar',
    data: {
      labels: state.map(data => data.name),
      datasets: [{
        data: state.map(data => data.value),
      }],
    },
    options: defaultOption(),
  });

  function validValue(value) {
    const { min, max } = myRadarChart.scale;

    if (value < min) { return min; }
    if (value > max) { return max; }
    return value;
  }

  function setValue(key, rawValue) {
    const value = validValue(rawValue);
    document.getElementById(`concierge_${key}`).value = value;

    myRadarChart.data.datasets.forEach((dataset) => {
      const { data } = dataset;
      data[state.map(dataSet => dataSet.key).indexOf(key)] = value;
    });

    myRadarChart.update();
  }

  element.onclick = (event) => {
    const { scale } = myRadarChart;
    const { pointLabels } = scale;
    const { x, y } = radarChartXY(event, myRadarChart);

    const category = categoryByXY({ categories: pointLabels, x, y });
    const value = radarChartValue({ x, y, scale });

    setValue(keyByName(state, category), value);
  };

  function getValue(key) {
    return Number(document.getElementById(`concierge_${key}`).value);
  }

  function changeByKey(key, value) {
    setValue(key, getValue(key) + value);
  }

  return {
    categoryPlus: (key, value) => changeByKey(key, value),
    categoryMinus: (key, value) => changeByKey(key, -value),
  };
}
