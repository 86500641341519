import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  connect() {
    this.loadTransit();
  }

  loadTransit() {
    axios.post(this.url, this.params)
      .then((response) => {
        this.element.outerHTML = response.data;
        window.activatePopover();
      });
  }

  get url() {
    return this.data.get('url');
  }

  get params() {
    return {
      plan_spot_id: this.data.get('planSpotId'),
      mode: this.data.get('mode'),
    };
  }
}
