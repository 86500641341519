import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Rails from 'rails-ujs';
import JC from '@machi-pla/japan_concierge';
import api from './api';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import loadMap from './my_plan';
import spotSearchForm from './spot_search_form';
import moveToElement from './move_to_element';

Rails.start();

const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
JC.start({ key: process.env.JC_API_KEY, url: process.env.JC_API_URL });

window.addEventListener('user-token', ({ detail: { token } }) => {
  api.post('/load_users', { token });
});

require('./concierge');
require('./analytics');

window.loadMap = google => loadMap(google);

spotSearchForm();
moveToElement();
