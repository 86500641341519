import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.unsubscribe = this.store.subscribe(this.update.bind(this));
    this.update();
  }

  disconnect() {
    this.unsubscribe();
  }

  update() {
    if (this.type === 'favorite' && this.favoriteSpotCount() !== 0) {
      this.modalTarget.classList.remove('d-block');
    }
  }

  close() {
    this.modalTarget.classList.remove('d-block');
  }

  favoriteSpotCount() {
    return Object.keys(this.store.getState().favoriteSpots).length;
  }

  get type() {
    return this.data.get('type');
  }

  get store() {
    return this.application.store;
  }
}
