import { Controller } from 'stimulus';
import axios from 'axios';

export default class PlanSpotController extends Controller {
  static targets = ['view', 'mode'];

  initialize() {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  }

  update() {
    axios.patch(`${this.url}.js`, { mode: this.modeTarget.value })
      .then(response => eval(response.data))
      .catch(error => console.log(error));
  }

  delete() {
    // Don't delete it more the once.
    if (this.viewTarget.classList.contains('fadeOut')) { return; }

    this.viewTarget.classList.add('animated', 'fadeOut');

    axios.delete(`${this.url}.js`)
      .then(response => eval(response.data))
      .catch(error => console.log(error));
  }

  get url() {
    return this.data.get('url');
  }
}
