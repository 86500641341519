import $ from 'jquery';

function regionMenu() {
  const activeClass = 'spot-search-form__pref-label--state_active';
  $('.spot-search-form__pref-label').on('click', (event) => {
    $('.spot-search-form__pref-label').removeClass(activeClass);

    const targetElement = $(event.target);
    targetElement.addClass(activeClass);
    $('#q_region').val(targetElement.data('key'));
    $('#new_q').find("input[id='area_update']").click();
  });
}

function categoryMenu() {
  $('.spot-category').on('click', (event) => {
    if (event.target.checked) {
      $(event.target).closest('label').find('.spot-sub-category').prop('checked', false);
    }
  });

  $('.spot-sub-category').on('click', (event) => {
    if (event.target.checked) {
      $(event.target).closest('label').parent().find('.spot-category')
        .prop('checked', false);
    }
  });
}

export default function spotSearchForm() {
  window.onpopstate = (event) => {
    if (event.state.type === 'search') {
      window.location.reload(true);
    }
  };
  regionMenu();
  categoryMenu();
}
