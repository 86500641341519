import { Controller } from 'stimulus';
import api from '../api';

export default class extends Controller {
  delete() {
    api.delete(this.url);
    this.element.remove();
  }

  get url() {
    return this.data.get('url');
  }
}
