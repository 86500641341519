import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['map', 'lat', 'lng'];

  connect() {
    this.waitForGoogle();
  }

  waitForGoogle = () => {
    if(typeof window.google !== "undefined") {
      this.load();
    } else {
      setTimeout(this.waitForGoogle, 250);
    }
  }

  getLat() {
    return parseFloat(this.latTarget.value);
  }

  getLng() {
    return parseFloat(this.lngTarget.value);
  }

  getLatLng() {
    return { lat: this.getLat(), lng: this.getLng() };
  }

  setLatLng(latLng) {
    this.latTarget.value = latLng.lat();
    this.lngTarget.value = latLng.lng();
  }

  load() {
    this.map = new window.google.maps.Map(this.mapTarget, { zoom: 13, center: this.getLatLng() });
    this.marker = new window.google.maps.Marker({ position: this.getLatLng(), map: this.map});

    window.google.maps.event.addListener(this.map, 'click', ({ latLng }) => {
      this.setNewPosition(latLng);
    });
  }

  setNewPosition(latLng) {
    this.setLatLng(latLng)
    this.moveMarker();
  }

  moveMarker() {
    if (this.marker) {
      const currentPosition = this.getLatLng();

      this.marker.setPosition(currentPosition);
      this.map.panTo(currentPosition);
    }
  }
}
