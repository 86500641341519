export default function moveToElement() {
  const params = new URLSearchParams(document.location.search.substring(1));
  const scrallElementID = params.get('element');

  if (scrallElementID) {
    const { offsetTop } = document.getElementById(scrallElementID);
    const headerHeight = document.getElementById('header').offsetHeight;
    window.scrollTo(0, offsetTop - headerHeight - 10);
  }
}
