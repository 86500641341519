import { Controller } from 'stimulus';
import api from '../api';

export default class extends Controller {
  static targets = ['modal'];

  showFavoriteSpots = () => {
    api.get(this.favoriteSpotsUrl)
      .then(({ data }) => {
        this.modalTarget.innerHTML = data;
        this.modalTarget.classList.add('favs-modal');
        window.$(this.modalTarget).modal('show');
      });
  }

  get favoriteSpotsUrl() {
    return this.data.get('favoriteSpotsUrl');
  }
}
