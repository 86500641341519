import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['region', 'area', 'submit']

  setArea() {
    this.areaTarget.innerHTML = JSON.parse(this.element.dataset.areas)[this.regionTarget.value];
  }

  setStartPoint() {
    this.submitTarget.click();
  }
}
