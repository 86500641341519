import { Controller } from 'stimulus';
import api from '../api';

export default class extends Controller {
  connect() {
    this.element.appendChild(this.iframe());
    window.addEventListener('message', this.jcTokenReciver, false);
  }

  disconnect() {
    window.removeEventListener('message', this.jcTokenReciver, false);
  }

  iframe() {
    const element = document.createElement('iframe');
    element.classList.add(this.hiddenClass);
    element.src = this.iframeSRC;
    return element;
  }

  jcTokenReciver = (event) => {
    if (event.type === 'message' && event.origin === this.apiDomain) {
      this.setToken(event.data.token);
    }
  }

  setToken(token) {
    localStorage.setItem('jc-user-token', token);
    api.post(this.userPath, { token }).then(() => {
      const event = new CustomEvent('user-identified', { bubbles: true });
      this.element.dispatchEvent(event);
    });
    this.element.innerHTML = '';
  }

  get userPath() {
    return this.data.get('userPath');
  }

  get iframeSRC() {
    return `${this.apiDomain}/user?api_key=${this.apiKey}`;
  }

  get hiddenClass() {
    return this.data.get('hideClass');
  }

  get apiDomain() {
    return this.data.get('apiDomain');
  }

  get apiKey() {
    return this.data.get('apiKey');
  }
}
