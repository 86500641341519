import axios from 'axios';
import { csrfToken } from 'rails-ujs';
import qs from 'qs';

const api = axios.create({
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
});

api.interceptors.request
  .use(config => ({ ...config, headers: { ...config.headers, 'X-CSRF-Token': csrfToken() } }));

export default api;
