import $ from 'jquery';
import axios from 'axios';
import radarChart from './radar_chart';
import noUiSlider from 'nouislider';

function chartUi(elementId) {
  const initData = $(`#${elementId}`).data('chart');
  const chart = radarChart('character-chart', initData);

  $('.chart-ui').on('click', ({ target }) => {
    const key = $(target).data('key');

    if ($(target).hasClass('plus')) {
      chart.categoryPlus(key, 5);
    } else {
      chart.categoryMinus(key, 5);
    }
  });
}

function inputSlider(elementId) {
  const element = document.getElementById(elementId);
  const input = element.querySelector('input');
  const startValue = Number(input.value);

  const options = {
    connect: true,
    start: startValue,
    range: {
      min: 0,
      max: 100,
    },
  };

  noUiSlider.create(element, options).on('update', (value) => {
    input.value = value;
  });
}

function updateArea() {
  $('#concierge_region').on('change', () => {
    axios.post(`${$('#new_concierge')[0].action}.js?${$('#new_concierge').serialize()}`)
      .then(response => eval(response.data));
  });
}

function initConciergeForm() {
  updateArea();

  chartUi('character-chart');


  inputSlider('popularity');
  inputSlider('activity');
  inputSlider('neighborhood');
  inputSlider('demographic');
}

if ($('#character-chart').length > 0) {
  initConciergeForm();
}
