function gtag(...args) {
  window.dataLayer.push(args);
}

if (process.env.ANALYTICS_KEY) {
  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', process.env.ANALYTICS_KEY, { transport_type: 'beacon' });
}
