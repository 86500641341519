import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.observer = new MutationObserver(this.resize);
    this.observer.observe(this.element, { childList:true, subtree:true });
    document.body.onresize = this.resize;
    this.sendHightMessage();
  }

  sendHightMessage() {
    const currentHeight = this.height();
    if (this.previousHeight !== currentHeight) {
      window.parent.postMessage({
        type: 'iframe',
        height: currentHeight,
      }, '*')
      this.previousHeight = currentHeight;
    }
  }

  resize = (event) => {
    this.sendHightMessage();
  }

  height() {
    return document.body.offsetHeight;
  }
}
