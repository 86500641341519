import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['count'];

  connect() {
    this.unsubscribe = this.store.subscribe(this.update.bind(this));
    this.update();
  }

  disconnect() {
    this.unsubscribe();
  }

  update() {
    this.countTarget.innerHTML = this.favoriteSpotCount();
  }

  favoriteSpotCount() {
    return Object.keys(this.store.getState().favoriteSpots).length;
  }

  get store() {
    return this.application.store;
  }
}
